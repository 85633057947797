<template>
    <div v-loading.fullscreen.lock="loading">
        <enterprise-search-bar-component
            add_auth="新增企业"
            @search="get_enterprise_index"
            @addTeam="addTeam"
        ></enterprise-search-bar-component>
        <common-table-component
            details_auth="企业详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_enterprise"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_enterprise_index"
        ></common-page-component>
        <enterprise-edit-component
            edit_auth="修改企业"
            del_auth="删除企业"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :enterprise_details_data="fenterprise_details_data"
            :foptions="foptions"
            @exitEnterpriseDialog="dialogExit"
            @search="get_enterprise_index"
            @show_edit="show_edit"
            @details_row="details_enterprise"
        ></enterprise-edit-component>
    </div>
</template>

<script>
import { enterprise_index_request,enterprise_details_request } from '@/network/enterprise.js'
import { staff_list_request } from '@/network/list.js'

import EnterpriseSearchBarComponent from '@/components/enterprise/EnterpriseSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import EnterpriseEditComponent from '@/components/enterprise/EnterpriseEditComponent'

export default {
    name: 'EnterpriseView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                director_name: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '企业名称',
                    minWidth: '260px'
                },
                {
                    prop: 'director_name',
                    label: '企业负责人',
                    minWidth: '100px'
                },
            ],
            fenterprise_details_data: {},
            foptions: [],
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_enterprise_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.director_name =
                param.director_name ?? this.cond.director_name
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            enterprise_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_enterprise(id) {
            this.fid = id
            this.fdialogFormVisible = true
            enterprise_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fenterprise_details_data = s.result
                        //获取企业下的所有员工
                        staff_list_request({enterprise_id:s.result.id})
                            .then((s) => {
                                this.foptions = s.result
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_enterprise_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        EnterpriseSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        EnterpriseEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>